import { Card as MuiCard, styled } from '@mui/material';

const Card = styled(MuiCard)(({ theme }) => ({
  padding: '0.5rem',
  borderRadius: '0.5rem 0.5rem 2rem 2rem',
  borderTop: `solid 0.5rem ${theme.palette.secondary.main}`,

  '&:hover': {
    opacity: 0.9,
  },
}));

export default Card;

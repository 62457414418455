import { createTheme } from '@mui/material';

import colors from './../style/palette.module.scss';
import { sizes } from './sizes';

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: colors.white,
          fontSize: '1rem',
          backgroundColor: colors.pink, // TODO _ utiliser couleur "secondary" ?
          borderRadius: sizes.borderRadius,
          padding: '0.5rem 1.2rem',
          '&:hover': {
            backgroundColor: colors.purple, // TODO _ utiliser couleur "primary" ?
          },
          '&.MuiButton-outlinedPrimary': {
            backgroundColor: 'transparent',
            color: colors.pink, // TODO _ utiliser couleur "secondary" ?
            borderColor: colors.pink, // TODO _ utiliser couleur "secondary" ?
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
  palette: {
    primary: {
      light: colors.purpleLight,
      main: colors.purple,
      dark: colors.purpleDark,
      contrastText: colors.white,
    },
    secondary: {
      light: colors.pinkLight,
      main: colors.pink,
      dark: colors.pinkDark,
      contrastText: colors.white,
    },
  },
});

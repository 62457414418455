import React from 'react';

import { kebabCase } from 'lodash';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  CardActions,
  CardContent,
  Container,
  Grid2,
  Typography,
} from '@mui/material';

import { Card } from '../../clux-ui';
import { ROUTES_ATELIERS } from '../../constants/routes';

const ChoixAtelier = () => {
  const navigate = useNavigate();

  return (
    <Container sx={{ marginTop: 5 }}>
      <Grid2 container spacing={2}>
        {Object.values(ROUTES_ATELIERS).map((atelier) => (
          <Grid2
            key={`atelier-${kebabCase(atelier.label)}`}
            size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
          >
            <Card>
              <CardContent>
                <Typography variant="h5">{atelier.label}</Typography>
                <Typography>{atelier.description}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => {
                    navigate(atelier.path);
                  }}
                  size="small"
                >
                  Demarrer
                </Button>
              </CardActions>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
};

export default ChoixAtelier;

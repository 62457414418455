import { keys } from 'lodash';

import { STEPS } from '../../../constants/values';

export interface LectureFlashParams {
  words: Array<string>;
  nbWords: number;
  wordsToDisplay: Array<string>;
  displayDuration: number;
  symboleAttente: string;
  step: string;
  index: number;
}

export const LECTURE_FLASH_DURATIONS = {
  0: { value: 10, label: '10ms' },
  4: { value: 25, label: '25ms' },
  8: { value: 50, label: '50ms' },
  16: { value: 100, label: '0.1s' },
  28: { value: 300, label: '0.3s' },
  36: { value: 500, label: '0.5s' },
  45: { value: 750, label: '0.75s' },
  54: { value: 1000, label: '1s' },
  59: { value: 1500, label: '1.5s' },
  64: { value: 2000, label: '2s' },
  73: { value: 3000, label: '3s' },
  82: { value: 4000, label: '4s' },
  91: { value: 5000, label: '5s' },
  100: { value: 9999, label: '∞' },
};

export const getSliderLabel = (value) => LECTURE_FLASH_DURATIONS[value]?.label;
export const getSliderValue = (value) => LECTURE_FLASH_DURATIONS[value]?.value;

const DEFAULT_WORDS = ['Chien', 'Guitare', 'Fraise', 'Après-midi'];

export const LECTURE_FLASH_PARAMS_DEFAULT_VALUES: LectureFlashParams = {
  words: DEFAULT_WORDS,
  nbWords: 2,
  wordsToDisplay: DEFAULT_WORDS,
  displayDuration: Number(keys(LECTURE_FLASH_DURATIONS)[5]),
  symboleAttente: '•',
  step: STEPS.PRESENTATION,
  index: -1,
};

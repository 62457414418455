import React from 'react';

import { Box, Container, Paper, Stack, Typography } from '@mui/material';

import { Boutons, Couleurs, Modales, Typographie } from '.';

const StyleGuide = () => {
  const sections = [
    { titre: 'Couleurs', compo: <Couleurs /> },
    { titre: 'Typographie', compo: <Typographie /> },
    { titre: 'Boutons', compo: <Boutons /> },
    { titre: 'Modales', compo: <Modales /> },
  ];

  return (
    <Container>
      <Stack spacing={2} mt={5} mb={5}>
        {sections.map((section) => (
          <Paper key={section.titre}>
            <Box p={2}>
              <Typography variant="h5" gutterBottom>
                {section.titre}
              </Typography>
              {section.compo}
            </Box>
          </Paper>
        ))}
      </Stack>
    </Container>
  );
};

export default StyleGuide;
